// Initialize dropdowns with vanilla JS
const dropdownElements = document.querySelectorAll('[data-bs-toggle="dropdown"]');
dropdownElements.forEach(element => {
    element.addEventListener('click', function(event) {
        event.preventDefault();
        const menu = element.nextElementSibling;
        if (menu) {
            menu.classList.toggle('show');
        }
    });
});

// Initialize modals with vanilla JS
const modalElements = document.querySelectorAll('[data-bs-toggle="modal"]');
modalElements.forEach(element => {
    element.addEventListener('click', function(event) {
        event.preventDefault();
        const target = document.querySelector(element.getAttribute('data-bs-target'));
        if (target) {
            target.classList.add('show');
            target.style.display = 'block';
            target.setAttribute('aria-modal', 'true');
            target.setAttribute('role', 'dialog');
            document.body.classList.add('modal-open');
        }
    });
});

document.addEventListener('click', function(event) {
    if (event.target.classList.contains('modal')) {
        event.target.classList.remove('show');
        event.target.style.display = 'none';
        event.target.removeAttribute('aria-modal');
        event.target.removeAttribute('role');
        document.body.classList.remove('modal-open');
    }
});

// Initialize collapses with vanilla JS
const collapseElements = document.querySelectorAll('[data-bs-toggle="collapse"]');
collapseElements.forEach(element => {
    element.addEventListener('click', function(event) {
        event.preventDefault();
        const target = document.querySelector(element.getAttribute('data-bs-target'));
        if (target) {
            target.classList.toggle('show');
        }
    });
});


let lastUpdated = 0;
function checkForUpdates() {
    // Get the relative base path from a data attribute on the body tag
    let basePath = document.body.dataset.basePath || '';
    const sentinelPath = `${basePath}sentinel.json`;

    fetch(sentinelPath)
        .then(response => response.json())
        .then(data => {
            if (lastUpdated === 0) {
                lastUpdated = data.last_updated;
            } else if (data.last_updated > lastUpdated) {
                location.reload();
            }
        });
}
if (window.location.hostname === 'localhost') {
    setInterval(checkForUpdates, 100); // Check every second
}

